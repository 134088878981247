.app-bar{
    height: 40px;
}

.nav-link {
    float: left;
  color: #cec6c6;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.nav-link-one {
    float: center;
    color: #cec6c6;
    text-align:center;
    text-decoration: none;
    font-weight: bold;
}

.simple {
    color: whitesmoke;
}

.homepage {
    background-color: rgb(209, 173, 72);
}

.header-two {
    color: rgb(49, 40, 97);
    text-align: center;
    font-size: 20px;
    background-color: rgb(229, 230, 233);
    padding: 10px;
}

.main-div {
    background: -webkit-linear-gradient(left, #666b72, #bfc9cc);
    margin-right: 0px;
    padding-right: 0px;
    background-size: 100%;
}

.main-grid {
    /* background-color: rgb(229, 230, 233); */
    background: -webkit-linear-gradient(left, #666b72, #bfc9cc);
    margin-top: -5px;
    margin-right: 0px;
    padding-right: 0px;
    background-size: 100%;
}

.mediacardorionunlock_div {
    margin-left: 50px;
}

.orionunlock_text_div {
    background: -webkit-linear-gradient(left, #666b72, #bfc9cc);
    margin-right: 0px;
    padding-right: 0px;
}

.div_funkcionalnosti {
  width: 150px;
  height: 200px;
  display: grid;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 50%;
  color: black;
}  


.flex-child:first-child {
  margin-right: 20px;
}

.green {
  font-size: 15px;
  margin-right: 20px;
  padding-right: 50px;
  padding-top:20px;
  text-align: justify;
}

.predaimage {
  margin-right: 20px;
}

/* .player {
  margin-left: 105px;
  padding-left: 50px;
} */

.wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  margin-left: 12vw;
  width: 50%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .flex-container {
  display: inline-block;
}
.green {
  font-size: 15px;
  margin-left: 30px;
  margin-top: 20px;
  padding-right: 10px;
  padding-top:45px;
  text-align: justify;
}

.magenta {
  margin-left: 20px;

}

.magenta1 {
  margin-left: 60px;
}

/* .player {
  margin-left: 10px;
  width: 340px;
  height: 295px;
  
  } */

  .wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .player {
    position: absolute;
    top: 0;
    margin-left: 12vw;
    width: 75%;
    height: 90%;
  }

}

.oriontitle {
  
  text-align: center;
}

.main-grid h2 {
    text-align: center;
    margin-top: 15px;
}

.nav-link-card {
    text-decoration: none;
  }

.card-typography {
    text-align: justify;
}

body{
    background: -webkit-linear-gradient(left, #666b72, #bfc9cc);
    margin-right: 0px;
    padding-right: 0px;
    background-size: 100%;
}
.contact-form{
    background: #fff;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 70%;
}

.history{
    background: -webkit-linear-gradient(left, #666b72, #bfc9cc);
    margin-top: 15%;
    margin-bottom: 5%;
    width: 70%;  
}

.history h4{
    margin-bottom: 8%;
    margin-top: -10%;
    text-align: justify;
    color: #313335;
}

.contact-form .form-control{
    border-radius:1rem;
}
.contact-image{
    text-align: center;
}
.contact-image img{
    border-radius: 6rem;
    width: 20%;
    margin-top: -3%;
    /* transform: rotate(29deg); */
}
.contact-form form{
    padding: 10%;
}
.contact-form form .row{
    margin-bottom: -7%;
}
.contact-form h3{
    margin-bottom: 8%;
    margin-top: -10%;
    text-align: center;
    color: #4c5157;
}
.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    background: #574e4f;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}
.btnContactSubmit
{
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
}

  .footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background-color:#282d32;
  }
  
  .footer-dark h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .footer-dark ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
  }
  
  .footer-dark ul a:hover {
    opacity:0.8;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom:36px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    opacity:0.6;
    margin-bottom:0;
  }
  
  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
  }
  
  .footer-dark .item.social > a:hover {
    opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }
  


  