.App {
  text-align: center;
  background-color: black;
  margin-right: 0px;
  padding-right: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar.navbar-default {
  background-color: #464545;
  border-color: #464545;
  z-index: 1;
}
.navbar.navbar-default .navbar-brand {
  color: #464545;
}
.navbar.navbar-default .navbar-brand:hover,
.navbar.navbar-default .navbar-brand:focus {
  color: #464545;
}
.navbar.navbar-default .navbar-text {
  color: #464545;
}
.navbar.navbar-default .navbar-nav > li > a {
  color: #464545;
  background-color: #464545;
  z-index: 1;
}
.navbar.navbar-default .navbar-nav > li > a:hover,
.navbar.navbar-default .navbar-nav > li > a:focus {
  color: #464545;
  background-color: #464545;
  z-index: 1;
}
.navbar.navbar-default .navbar-nav > .active > a,
.navbar.navbar-default .navbar-nav > .active > a:hover,
.navbar.navbar-default .navbar-nav > .active > a:focus {
  color: #464545;
  background-color: #464545;
}
.navbar.navbar-default .navbar-nav > .open > a,
.navbar.navbar-default .navbar-nav > .open > a:hover,
.navbar.navbar-default .navbar-nav > .open > a:focus {
  color: #464545;
  background-color: #464545;
  z-index: 1;
}
.navbar.navbar-default .navbar-toggle {
  border-color: #464545;
  background-color: #867d7d;
  z-index: 1;
}
.navbar.navbar-default .navbar-toggle:hover,
.navbar.navbar-default .navbar-toggle:focus {
  background-color: #464545;
  z-index: 1;
}

#basic-nav-dropdown1{
  background-color: #353a3d;
  color: #8b8989;
  float: none;
  display: inline-block;
  align-content: center;
  justify-content: center;
  margin-right: 20px;
  text-align: center;
}


#basic-nav-dropdown1:hover {
  background-color: #464c50;
  float: none;
  display: inline-block;
  align-content: center;
  justify-content: center;
}

#basic-nav-dropdown1:active {
  float: none;
  display: inline-block;
  align-content: center;
  justify-content: center;
}

#collasible-nav-dropdown {
  background-color: hsl(220, 13%, 18%, 4%);
  text-align: center;
  align-content: center;
  float: none;
  display: inline-block;
  align-content: center;
  justify-content: center;
}

.dropdown {
  padding: 0px 0px;
  background-color: #353a3d;
  color: #8b8989;
  align-content: center;
  justify-content: center;  
}

.dropdown-menu { 
  background-color: #353a3d; 
  color: #8b8989;
  align-content: center;
  justify-content: center;
  border: #353a3d;
  max-width: fit-content;
 }

.responsive-navbar-nav {
  text-align: center;
  background-color: #353a3d;
  color: #8b8989;
  float: none;
  display: inline-block;
  align-content: center;
  justify-content: center;
}